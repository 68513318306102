<template>
  <div class="presupuestador-menu">
   
    <div class="budget">
      <form @submit.prevent="submitForm">
            <div>
              <label style="margin-right: 8px;">Firma: </label>
              <canvas
                ref="canvas"
                :width="canvasWidth"
                :height="canvasHeight"
                style="border: 1px solid #ced4da;"
              ></canvas>
              <div>
              <button
                type="button"
                class="boton"
                @click.prevent.stop="limpiarFirma"
              >
                Limpiar Firma
              </button>
              </div>
            </div>
        <div class="contenedor-botones">
        <div class="boton-enviar">
          <button :class="disabledButton ? 'submit-desabilitado' : 'submit'" :disabled="disabledButton" type="submit">Enviar</button>
        </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { PDFDocument } from "pdf-lib";
import { initializeApp } from "firebase/app"
import { getStorage, ref, uploadBytes } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCX6kqJyaW8d7jmAmomWNkKFEjSTEboIDg",
  authDomain: "pdf-credito-d72e6.firebaseapp.com",
  projectId: "pdf-credito-d72e6",
  storageBucket: "pdf-credito-d72e6.appspot.com",
  messagingSenderId: "13266000813",
  appId: "1:13266000813:web:f4e878c57d4e571fad1f24",
  measurementId: "G-56F8P4D7QM",
};

export default {
  data() {
    return {
      firma: null,
      canvasWidth: 280,
      canvasHeight: 100,
      pdfBytes: null,
    }
  },
 mounted(){
     this.inicializarCanvas();
  },
  computed: {
    disabledButton() {
      if(this.firma){
        return false;
      }else {
        // Valor de retorno predeterminado
        return true;
      }
    }
  },
  methods: {
     inicializarCanvas() {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, this.canvasWidth, this.canvasHeight);

      let dibujando = false;
      let lastX = 0;
      let lastY = 0;

      const dibujar = (x, y) => {
        if (!dibujando) return;
        ctx.beginPath();
        ctx.moveTo(lastX, lastY);
        ctx.lineTo(x, y);
        ctx.strokeStyle = "black";
        ctx.lineWidth = 2;
        ctx.stroke();
        [lastX, lastY] = [x, y];
      };

      const iniciarDibujo = (x, y) => {
        dibujando = true;
        [lastX, lastY] = [x, y];
      };

      const finalizarDibujo = () => {
        dibujando = false;
        this.guardarFirma();
      };

      // Eventos para el mouse
      canvas.addEventListener("mousedown", (e) => iniciarDibujo(e.offsetX, e.offsetY));
      canvas.addEventListener("mousemove", (e) => dibujar(e.offsetX, e.offsetY));
      canvas.addEventListener("mouseup", finalizarDibujo);
      canvas.addEventListener("mouseout", finalizarDibujo);

      // Eventos para dispositivos táctiles
      canvas.addEventListener("touchstart", (e) => {
        const touch = e.touches[0];
        const rect = canvas.getBoundingClientRect();
        iniciarDibujo(touch.clientX - rect.left, touch.clientY - rect.top);
      });

      canvas.addEventListener("touchmove", (e) => {
        e.preventDefault(); // Prevenir el desplazamiento de la página al dibujar
        const touch = e.touches[0];
        const rect = canvas.getBoundingClientRect();
        dibujar(touch.clientX - rect.left, touch.clientY - rect.top);
      });

      canvas.addEventListener("touchend", finalizarDibujo);
      canvas.addEventListener("touchcancel", finalizarDibujo);
    },
    limpiarFirma() {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    },
    guardarFirma() {
      const canvas = this.$refs.canvas;
      this.firma = canvas.toDataURL();
    },
    async rellenarPDF() {
      // Cargar el PDF existente
      const urlPDF =
        "https://abba-ahorro-inversion.s3.us-east-2.amazonaws.com/SOLICITUD+SURAFINANCIA+INDEPEDIENTE";
      const existingPDFBytes = await fetch(urlPDF).then((res) =>
        res.arrayBuffer()
      );

      // Crear un nuevo documento PDF
      const pdfDoc = await PDFDocument.load(existingPDFBytes);
     
      const imageBytes = Uint8Array.from(atob(this.firma.split(",")[1]), (c) =>
        c.charCodeAt(0)
      );
      
      const signatureImage = await pdfDoc.embedPng(imageBytes);
      const pages = pdfDoc.getPages();
      const thirdPage = pages[2];
      const { width, height } = thirdPage.getSize();
      const imageWidth = signatureImage.width;
      const imageHeight = signatureImage.height;
      const x = width - imageWidth - 65; // Ajustar 50 según sea necesario
      const y = height - imageWidth - 410; // Ajustar según sea necesario

      // Dibujar la imagen de la firma en la tercera página
      thirdPage.drawImage(signatureImage, {
        x: x,
        y: y,
        width: imageWidth,
        height: imageHeight,
      });

      // Guardar el PDF modificado
      const pdfBytes = await pdfDoc.save();
      return pdfBytes;
    },
    async guardarPDFFirestore(pdfBytes) {
      try {
        const app = initializeApp(firebaseConfig);
        const storage = getStorage(app);

        // Guardar el PDF en Firestore
        const fileName = `pdf_${new Date().getTime()}.pdf`;

        // Crear una referencia al archivo en Firebase Storage
        const storageRef = ref(storage, fileName);

        // Subir los bytes del PDF al almacenamiento
        await uploadBytes(storageRef, pdfBytes);

        // console.log('PDF almacenado en Firestore con ID:');
      } catch (error) {
        console.error("Error al almacenar el PDF en Firestore:", error);
      }
    },
    async submitForm() {
      try {
        // Rellenar el PDF con las respuestas
        this.pdfBytes = await this.rellenarPDF();

        // Mostrar el PDF diligenciado en el iframe
        await this.guardarPDFFirestore(this.pdfBytes);
        window.location.href =
          "https://abbaseguros.co/SuraFinancia/compra-vehiculo";
      } catch (error) {
        console.error("Error al rellenar el PDF:", error);
      }
    },
  },
};
</script>

<style scoped>
.presupuestador-menu {
  position: relative;
  max-width: 100%;
  padding: 1.25rem;
  color: #333333;
  margin-top: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.backdrop {
  height: 100%;
  width: 100%;
  opacity: 0.7;
  /*background-color: rgba(0, 0, 0, 0.75);*/
  z-index: 10;
}

form {
  width: 100%;
}

.menu-progreso {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.numero {
  width: 35px;
  height: 35px;
  border: 3px solid #ddd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin: 0;
  position: relative;
}

.numero-con-relleno {
  border: #3498db;
  background-color: #3498db;
  color: #fff;
}

.linea {
  flex: 1;
  height: 5px;
  background-color: #ddd;
  margin: 0;
  z-index: -1;
}

.linea-con-relleno {
  background-color: #3498db;
}

.budget {
  display: flex;
  flex-direction: column;
}

label {
  font-weight: bold;
  color: #555555;
  margin: 0 0 4px;
}

input{
  margin: 0 0 15px;
  /*padding: 1px 0 1px 9px;*/
}

.contenedor-botones {
  display: flex;
}

.boton {
  /*padding: 10px 20px;*/
  padding: 11px;
  /*margin: 0 10px;*/
  margin: 15px 0;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  outline: none;
}

.widthBoton {
    width:127.81px;
}

.boton-enviar{
  display: grid;
  justify-content: center;
  margin: 15px 0;
}

.submit{
    all: unset;
    font-family: Helvetica, Arial, sans-serif;
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #f08935;
    color: #fff;
    font-size: 18px;
    border-radius: 5px;
    padding: 0 30px;
    font-weight: bold;
    height: 45px;
    cursor: pointer;
    line-height: 45px;
    text-align: center;
   /*margin: 15px 0;*/
    text-decoration: none;
}

.submit:hover {
  background-color: #f58021;
}

.submit-desabilitado {
  all: unset;
    font-family: Helvetica, Arial, sans-serif;
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #6c757d;
    border-color: #6c757d;
   border: none;
    color: #fff;
    font-size: 18px;
    border-radius: 5px;
    padding: 0 30px;
    font-weight: bold;
    height: 45px;
    cursor: pointer;
    line-height: 45px;
    text-align: center;
   /*margin: 15px 0;*/
    text-decoration: none;
     outline: none;
    opacity: 0.65;
}

.boton-desabilitado {
  margin: 15px 0;
  font-size: 18px;
  padding: 11px;
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  border: none;
  border-radius: 5px;
  outline: none;
  opacity: 0.65;
}
.contenedor-botones button:nth-child(1) {
  margin-left: 0;
  margin-right: 30px;
}

.boton:hover {
  background-color: #2980b9;
}

.section-form__egresos h3 {
  margin-bottom: 13px;
}

.section-form__egreso {
  display: grid;
  grid-template-columns: 45% 45%;
  margin-bottom: 12px;
  align-items: center;
}

/*.section-form__ingresos div:nth-child(1) {
  display: grid;
  align-items: center;
}

.section-form__ingresos div:nth-child(2) {
  display: flex;
  flex-direction: column;
}*/

.section-form__referencias {
  display: grid;

}

.section-form__referencia {
  display: flex;
  flex-wrap: wrap;
}

.section-form__referencia div {
   margin-right: 12px;
   width: 100%;
}

.section-form__referencia input{
  /*max-width: 155px;*/
}

@media (min-width: 22.5rem) {
  .section-form__referencia div {
   width: 45%;
}
}

@media (min-width: 31.25rem) {
  .presupuestador-menu {
    padding: 2.5rem;
  }
}

@media (min-width: 37.5rem) {
  .grupo-OtrosGastos {
    display: grid;
    grid-template-columns: 44% 46%;
  }

  .grupo-OtrosGastos_familiares {
    margin-left: 10px;
  }

  .section-form__referencia div {
   width: 30%;
}
}

@media (min-width: 49.1rem) {
  .presupuestador-menu {
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    margin: 1.25rem;
  }

  .budget {
    flex-direction: row;
  }

  form {
    order: 2;
    padding-left: 40px;
  }
}

@media (min-width: 62.5rem) {
  .presupuestador-menu {
    margin: 2rem;
  }

  .section-form div {
    display: grid;
    grid-template-columns: 45% 45%;
  }

  .section-form label {
    margin-right: 10px;
  }

  .section-form input {
    margin-right: 10px;
  }

  .grupo-ingresos {
    display: grid;
    grid-template-columns: 45% 45%;
  }

  .grupo-ingresos_honorarios {
    margin-left: 10px;
  }
}
</style>